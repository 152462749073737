import React, {useState} from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './../styles/all.scss'
import useSiteMetadata from './SiteMetadata'
import PropTypes from "prop-types";

const TemplateWrapper = ({ page, children }) => {
  const { title, description } = useSiteMetadata();
  const [navOpen, setNavOpen] = useState(false);
  const navBar = React.createRef();

  const handleNavBarOpen = (open) => {
      setNavOpen(open);
  };

  const getStyleObj = () => {
      if (navOpen == false) return {};

      return {
          overflow : 'hidden',
          maxHeight: '100vh',
      };
  }

  return (
    <div className={`page-${page}`}>
      <Helmet>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1"/>
          <meta name="description" content={description}/>
          <title>{title}</title>
      </Helmet>
      <div style={getStyleObj()}>
          <Navbar onToggle={handleNavBarOpen} ref={navBar}/>
          <div>{children}</div>
          <Footer />
      </div>
    </div>
  )
}

TemplateWrapper.propTypes = {
    page: PropTypes.string
}

export default TemplateWrapper
